import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { FC } from 'react';
import getConfig from 'next/config';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { DefaultSeo } from 'next-seo';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { ToastContainer } from 'react-toastify';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { wrapper } from 'reducer/store';
import * as gtag from 'lib/gtag';
import theme from 'styles/theme';
import antdTheme from 'styles/antd-theme';
import GlobalStyle from 'styles/GlobalStyle';
import 'nprogress/nprogress.css';
import { login } from 'reducer/actions';
import { State } from 'reducer/reducer';
import { getUser, isTokenValid } from 'lib/authentication';
import 'react-toastify/dist/ReactToastify.css';
import { GTM_ID } from 'lib/gtag';

const LoginModal = dynamic(() => import('containers/LoginModal'), {
    ssr: false
});

export const App: FC<AppProps> = ({ Component, pageProps }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const loginModalVisible = useSelector((state: State) => state.loginModalVisible);
    useEffect(() => {
        const handleRouteChangeComplete = (url: string) => {
            gtag.pageview(url);
            NProgress.done();
        };

        const handleRouteChangeStart = () => {
            NProgress.start();
        };

        const handleRouteChangeError = () => {
            NProgress.done();
        };

        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('hashChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeError', handleRouteChangeError);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            router.events.off('hashChangeComplete', handleRouteChangeComplete);
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeError', handleRouteChangeError);
        };
    }, [router.events]);

    useEffect(() => {
        if (isTokenValid()) {
            dispatch(login(getUser()));
        }
    }, []);

    const { name, url } = getConfig().publicRuntimeConfig;
    const openGraph = {
        type: 'website',
        locale: 'pl_PL',
        url,
        site_name: name,
        description: `Bike & Business to specjalistyczny kwartalnik o tematyce technicznej. Głównymi tematami poruszanymi na jego łamach są zagadnienia związane z naprawą i diagnostyką motocykli, wyposażeniem naprawczym oraz rynkiem motocyklowym. Czasopismo tworzone jest we współpracy z redakcją tytułu „Bike und Business”, na licencji którego się ukazuje.`,
        images: [
            {
                url: `${url}/static/images/logo.svg`,
                width: 325,
                height: 46,
                alt: 'Logotype'
            }
        ]
    };

    const twitter = {
        cardType: 'summary_large_image'
    };

    return (
        <ThemeProvider theme={theme}>
            <ConfigProvider locale={plPL} theme={antdTheme}>
                <ToastContainer
                    position="top-right"
                    autoClose={8000}
                    hideProgressBar
                    newestOnTop
                    draggable={false}
                    closeOnClick
                    pauseOnHover
                />
                <DefaultSeo
                    titleTemplate={`%s | ${name}`}
                    defaultTitle={`Rynek Motocyklowy - naprawa i diagnostyka motocykli, wyposażenie naprawcze oraz rynek motocyklowy`}
                    description="Rynek Motocyklowy to specjalistyczny tytuł o tematyce technicznej. Głównymi tematami poruszanymi na jego łamach są zagadnienia związane z naprawą i diagnostyką motocykli, wyposażeniem naprawczym oraz rynkiem motocyklowym."
                    openGraph={openGraph}
                    twitter={twitter}
                />
                {loginModalVisible && <LoginModal />}
                <GoogleTagManager gtmId={GTM_ID} />
                <Component {...pageProps} />
            </ConfigProvider>
            <GlobalStyle />
        </ThemeProvider>
    );
};

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
    const { analyze } = getConfig().publicRuntimeConfig;
    if (analyze) console.log({ id, name, label, value });
    sendGTMEvent({
        event_label: id,
        name,
        event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value)
    });
}

export default wrapper.withRedux(App);
