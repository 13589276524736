import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    colors: {
        white: 'white',
        black: 'black',
        primary: '#cb212f',
        primaryBackground: '#cb212f',
        secondaryBackground: '#cb212f',
        grey: '#f4f4f4',
        greyishBrown: '#777777',
        greyishBrownTwo: '#444444',
        darkGrey: '#333333',
        derkerGrey: '#222222',
        brownishGrey: '#666666',
        blueGrey: '#96B0C3',
        lightBlue: 'red',
        dividerColor: 'rgba(203, 33, 47, 0.6)'
    },
    promotedSection: {
        colors: {
            primary: '#cb212f'
        }
    }
};

export default theme;
